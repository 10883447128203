<template>
  <div class="grid">
    <div class="col-m-7 desktop-only">
      <img
        class="collection__image img-block"
        :src="getDesktopContentImage(content)"
        alt=""
      >
    </div>
    <div class="col-1" />
    <div v-html="getMainContent(content)" class="collection__main-content col-12 col-m-4" />

    <div class="grid collection__main-content-video" v-if="hasVideo(content)">
      <div class="col-m-2"/>
      <div class="col-12 col-m-7">
        <SfVideo
          class="collection__video desktop-only"
          :src="getDesktopVideo(content)"
          :poster="getDesktopVideoPoster(content)"
        />
        <SfVideo
          class="collection__video mobile-only"
          :src="getMobileVideo(content)"
          :poster="getMobileVideoPoster(content)"
        />
      </div>
    </div>
  </div>
</template>

<script type="ts">
import { defineComponent } from '@nuxtjs/composition-api';
import { SfVideo } from '~/components';
import collectionGetters from '~/modules/catalog/category/getters/collectionGetters';

export default defineComponent({
  components: {
    SfVideo,
  },
  props: {
    content: {
      type: Object,
      default: null,
    },
  },
  setup() {

    return {
      ...collectionGetters
    }
  }
});
</script>

<style lang="scss" scoped>
.collection {
  &__item-image {
    @include for-mobile {
      margin: var(--spacer-lg) 0;
    }
  }

  &__main {

    @include for-desktop {
      margin-bottom: var(--spacer-lg);
    }

    &-content {
      display: flex;
      flex-direction: column;

      ::v-deep {

        .collection__main-content-image {
          margin: var(--spacer-xs) 0 var(--spacer-lg);
        }

        p {
          font-size: var(--font-size--base);
          line-height: var(--line-height--base);
          margin: 0 0 var(--spacer-base);

          @include for-desktop {
            font-size: var(--font-size--lg);
            line-height: var(--line-height--lg);
          }

          &:last-of-type {
            margin-bottom: var(--spacer-xs);
          }
        }

        img {
          display: block;
          width: 100%;
          max-width: 100%;
        }

        .bottom {
          margin-top: auto;
        }

        .right {
          margin-left: auto;
        }
      }
    }

    &-content-video {

      @include for-mobile {
        margin: var(--spacer-base) 0 var(--spacer-xs);
      }
    }
  }
}
</style>
